// Toaster Types
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

// Export
export const EXPORT = "EXPORT";
export const EXPORT_STATUS = "EXPORT_STATUS";
export const CLEAR_EXPORT = "CLEAR_EXPORT";

//User Types
export const USER = "USER";

//User Preferences
export const USER_PREFERENCES = "USER_PREFERENCES";

//Integration
export const INTEGRATION_STATUS = "INTEGRATION_STATUS";

// Jobs
export const CUSTOM_FIELDS_DATA = "CUSTOM_FIELDS_DATA";
export const TEAM_OPTIONS_DATA = "TEAM_OPTIONS_DATA";
export const JOBS_INITIAL_DATA = "JOBS_INITIAL_DATA";
export const ESTIMATE_OPTIONS_DATA = "ESTIMATE_OPTIONS_DATA";

// Catalog
export const CATALOG = "CATALOG";

export const SET_ACTIVE_FOLDER = "SET_ACTIVE_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const SELECTED_TYPE = "SELECTED_TYPE";
export const PARENT_FOLDER = "PARENT_FOLDER";
export const FLATTEN_OBJECT = "FLATTEN_OBJECT";
export const UPDATE_FOLDERS = "UPDATE_FOLDERS";
export const REFRESH_FOLDER = "REFRESH_FOLDER";
export const CATALOG_STATS = "CATALOG_STATS";
export const SEARCH_TOGGLE = "SEARCH_TOGGLE";

// products

export const SELECT_PRODUCT_ITEM = "SELECT_PRODUCT_ITEM";
export const CANCEL_SELECTED_PRODUCTS = "CANCEL_SELECTED_PRODUCTS";
export const SELECT_ALL_PRODUCTS = "SELECT_ALL_PRODUCTS";
export const SET_PRODUCT_ITEMS = "SET_PRODUCT_ITEMS";
export const SORT_AND_FILTERS = "SORT_AND_FILTERS";

// Services
export const CANCEL_SELECTED_SERVICES = "CANCEL_SELECTED_SERVICES";
export const SELECT_ALL_SERVICES = "SELECT_ALL_SERVICES";
export const SELECT_SERVICE_ITEM = "SELECT_SERVICE_ITEM";
export const SET_SERVICE_ITEMS = "SET_SERVICE_ITEMS";
export const SORT_AND_FILTERS_SERVICES = "SORT_AND_FILTERS_SERVICES";

const types = {
	ADD_TOAST,
	REMOVE_TOAST,
	EXPORT,
	EXPORT_STATUS,
	CLEAR_EXPORT,
	USER,
	USER_PREFERENCES,
	INTEGRATION_STATUS,
	CUSTOM_FIELDS_DATA,
	TEAM_OPTIONS_DATA,
	JOBS_INITIAL_DATA,
	ESTIMATE_OPTIONS_DATA,
	CATALOG,
	SET_ACTIVE_FOLDER,
	EDIT_FOLDER,
	SELECTED_TYPE,
	FLATTEN_OBJECT,
	UPDATE_FOLDERS,
	SELECT_PRODUCT_ITEM,
	CANCEL_SELECTED_PRODUCTS,
	SET_PRODUCT_ITEMS,
	SELECT_ALL_PRODUCTS,
	REFRESH_FOLDER,
	CATALOG_STATS,
	SORT_AND_FILTERS,
	CANCEL_SELECTED_SERVICES,
	SELECT_ALL_SERVICES,
	SELECT_SERVICE_ITEM,
	SET_SERVICE_ITEMS,
	SORT_AND_FILTERS_SERVICES,
	SEARCH_TOGGLE
};

export default types;
